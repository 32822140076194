import React from "react"
import styled from "styled-components"
import { FacebookIcon, InstaIcon, TwitterIcon } from "../../assets"
import { device } from "../../style"

export default function Icons({ ...rest }) {
  function renderIcon({ icon, style, link, linkComp: Link }) {
    return (
      <Anchor href={link} target="_blank">
        <Icon as={icon} />
      </Anchor>
    )
  }
  return (
    <div {...rest}>
      {renderIcon({
        icon: FacebookIcon,
        link: "https://www.facebook.com/rajarshi.biswas99",
      })}
      {renderIcon({
        icon: InstaIcon,
        link: "https://www.instagram.com/rajarshi.biswas99/",
      })}
      {renderIcon({
        icon: TwitterIcon,
        link: "https://twitter.com/RBiswas99",
      })}
    </div>
  )
}

const Icon = styled.svg`
  height: 1em;
  width: 1em;
  font-size: 4rem;
`

const Anchor = styled.a`
  &:not(:last-child) {
    margin-right: 2rem;
  }
`
