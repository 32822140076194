import React from "react"
import styled from "styled-components"
import { SubjectPng } from "../../assets"
import { device } from "../../style"
import Icons from "./Icons"

export const RightSection = styled(({ ...rest }) => {
  return (
    <Container {...rest}>
      {/* <Icons /> */}
      <Image src={SubjectPng} />
    </Container>
  )
})``

const Container = styled.div`
  font-size: 1rem;
  position: relative;
  ${device.tabPort} {
    padding: 3rem;
  }
  ${device.phone} {
    padding: 0;
  }
  max-width: 90rem;
`
const Image = styled.img`
  width: 100%;
`
