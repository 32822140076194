import React from "react"
import styled from "styled-components"
import { device } from "../../style"
import { Btn, BtnOutlined } from "./Button"
import IconsComp from "./Icons"
import { About, Greet, Name, NameAndGreet } from "./NameAndGreet"

export const LeftSection = styled(({ ...rest }) => {
  return (
    <Container {...rest}>
      <NameAndGreet>
        <Greet>Hello I am</Greet>
        <Name>Rajarshi Biswas</Name>
        <About>
          Currently studying Civil Engineering at Jadavpur University.
        </About>
      </NameAndGreet>

      <BtnTray>
        <Btn>Download My Resume</Btn>
        <BtnOutlined>
          <a href="https://wa.me/919830889529" target="_blank">
            Contact Me
          </a>
        </BtnOutlined>
      </BtnTray>
      <Icons />
    </Container>
  )
})``

const Container = styled.div`
  font-size: 16px;
`

const BtnTray = styled.div``

const Icons = styled(IconsComp)`
  margin-top: 4rem;

  ${device.phone} {
    display: flex;
    justify-content: center;
  }
`
